window.maxFormsConfig = window.maxFormsConfig || {};

(function ($) {
  // This hash tracks the errors on the screen so we know when the error container should be shown or hidden.
  var errorsToFix = {};

  function anyInvalid(constraints) {
    for (var constraint in constraints) {
      if (!constraints[constraint].valid) {
        return true;
      }
    }

    return false;
  }

  window.maxFormsConfig = $.extend(true, {}, window.maxFormsConfig, {
    errors: {
      container: function (elem, isRadioOrCheckbox) {
        return $('.oge-wizard-error-list');
      },
    },
    listeners: {
      onFieldError: function (elem, constraints, maxFormsField) {
        if (anyInvalid(constraints)) {
          errorsToFix[maxFormsField.hash] = 1;
          $('.oge-wizard-error-list').show();
          $('html, body').animate(
            { scrollTop: $('.oge-wizard-error-list').offset().top },
            'slow'
          );
        }
      },
      onFieldSuccess: function (elem, constraints, maxFormsField) {
        if (!anyInvalid(constraints)) {
          delete errorsToFix[maxFormsField.hash];
        }

        // Hide the error box if there are no more errors in the errorsToFix hash.
        if (
          $.map(errorsToFix, function (e, i) {
            return i;
          }).length == 0
        ) {
          $('.oge-wizard-error-list').hide();
        }
      },
    },
  });
})(window.jQuery || window.Zepto);
