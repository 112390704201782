function FormHandlebarsHelpers(fei) {
    Handlebars.registerHelper('getWidth', function(count) {
        if (count) {
            return (100 / count);
        } else {
            return 100;
        }
    });

    Handlebars.registerHelper('getSpan', function(count) {
        var span;
        switch (count) {
            case 2:
                span = "span6";
                break;
            case 3:
                span = "span4";
                break;
            case 4:
                span = "span3";
                break;
            case 5:
            case 6:
                span = "span2";
                break;
            default:
                span = "span12";
        }
        return span;
    });

    function ifequal(m, n, options) {
        if (m === n) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }
    }

    Handlebars.registerHelper('ifequal', ifequal);

    Handlebars.registerHelper('ifnotequal', function(m, n, options) {
        if (m === n) {
            return options.inverse(this);
        } else {
            return options.fn(this);
        }
    });


    Handlebars.registerHelper("generateElement", function(formElement) {
        var toReturn = "";
        var fieldType = formElement.fieldType;
        var instanceOfFieldType = fei.formFieldTypes[fieldType];
        if (instanceOfFieldType) {
            var fieldElement = formElement.fieldElement;
            var resultantFieldElement = instanceOfFieldType.mergeDesignElements(fei, fieldElement);

            var controls = {
                "ee": resultantFieldElement,
                "ve": resultantFieldElement
            };

            if (instanceOfFieldType.validateElements.fieldStructure) {
                instanceOfFieldType.validateElements.fieldStructure(resultantFieldElement, instanceOfFieldType.validateElements, fei.formStructureMessages, fei);
            }

            if (instanceOfFieldType.executeElements.dependencies) {
                if (instanceOfFieldType.executeElements.dependencies.onPreLoad) {
                    instanceOfFieldType.executeElements.dependencies.onPreLoad(controls.ec);
                }

                $.each(instanceOfFieldType.executeElements.dependencies.scripts, function(key, val) {
                    fei.instanceOfFormInteraction.fetchFile(fei.formsLibBasePath + val);
                });
                $.each(instanceOfFieldType.executeElements.dependencies.stylesheets, function(key, val) {
                    $("head").append("<link href='" + fei.formsLibBasePath + val + "' type='text/css' rel='stylesheet' />");
                });
            }

            fei.executeFieldType(fieldType, controls, function(html) {
                toReturn = html;
            });
        }
        return new Handlebars.SafeString(toReturn);
    });

    // attr : name of the attribute in the current context to be split, 
    //        will be forwarded to the descendants
    // count : number of elements in a group
    // opts : parameter given by Handlebar, opts.fn is the block template
    /*Handlebars.registerHelper('splitter', function (attr, count, opts) {
		var result, arr, i, zones, inject;

		arr = attr;
		zones = Math.ceil(arr.length / count);

		result="";
		for (i = 0; i < zones; i++) {
			inject = {index: arr.index};
			inject['elements'] = arr.slice(i * count, (i + 1) * count);

			result += opts.fn(inject);
		}

		return result;
	});*/

    Handlebars.registerHelper('setVar', function(varName, value) {
        this[varName] = value;
    });

    Handlebars.registerHelper('ifeithernonnull', function(var1, var2, opts) {
        if (var1 || var2) {
            return opts.fn(this);
        } else {
            return opts.inverse(this);
        }
    });

    Handlebars.registerHelper('getBootstrapGridSpan', function(section) {
        switch (section.fieldsets.length) {
            case 1:
                return 5;
            case 2:
                return 3;
            case 3:
            case 4:
                return 2;
            case 5:
                return 1;
        }

        return 1;
    });

    Handlebars.registerHelper('ifIsLastInArray', function(arrIdx, arrLen, opts) {
        if (arrIdx === arrLen - 1) {
            return opts.fn(this);
        } else {
            return opts.inverse(this);
        }
    });

    Handlebars.registerHelper('inArray', function(key, array, options) {
        if ($.inArray(key, array) !== -1) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }
    });

    Handlebars.registerHelper('dynamicBlock', function(blockHelper, context, options) {
        if ((/^{{([^}]*)}}$/).test(context)) {
            var customHelper = context.replace("{{", "").replace("}}", "");
            var customHelperParams = customHelper.split(" ");
            var customHelperFunction = customHelperParams[0];
            var customHelperData = customHelperParams[1];
            if (customHelperData) {
                context = Handlebars.helpers[customHelperFunction](customHelperData);
            } else {
                context = Handlebars.helpers[customHelperFunction]();
            }
        }
        return Handlebars.helpers[blockHelper](context, options);
    });

    Handlebars.registerHelper('dynamicEach', function(context, options) {
        return Handlebars.helpers.dynamicBlock('each', context, options);
    });

    Handlebars.registerHelper('json', function(obj) {
        return JSON.stringify(obj);
    });
}