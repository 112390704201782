/**
 * Creates an instance of FormType.
 *
 * @class FormType
 * @constructor
 * @this {FormType}
 * @param {string} name The name of form type.
 * @param {object} formType All the attributes and functions of a form type.
 */
function FormType(name, formType) {
    this.name = name;
    this.designElements = formType.designElements; // pass by referencew oaas by value
    this.designer = formType.designer;
    this.validateElements = formType.validateElements;
    this.executeElements = formType.executeElements;
    this.execution = formType.execution;
    this.populateData = formType.populateData;
    this.saveData = formType.saveData;
    this.mergedDesignElements = {};
    /**
     * Merge the design elements of Form JSON and default form type.
     *
     * @this {FormType}
     * @method mergeDesignElements
     * @param {object} fei Instance of FormExecutorInteraction.
     * @param {object} customDesignElements Design elemnts of Form JSON.
     * @return {object} Resultant object.
     */
    this.mergeDesignElements = function(fei, customDesignElements) {
        var resultantFormElement = $.extend(true, {}, this.designElements, customDesignElements);
        this.mergedDesignElements[resultantFormElement.name] = resultantFormElement;
        return resultantFormElement;
    };
}
/**
 * Creates an instance of FormFieldType.
 *
 * @class FormFieldType
 * @constructor
 * @this {FormFieldType}
 * @param {string} name The name of field type.
 * @param {object} fieldType All the attributes and functions of a field type.
 */
function FormFieldType(name, fieldType) {
    this.name = name;
    this.designElements = fieldType.designElements; // pass by referencew oaas by value
    this.validateElements = fieldType.validateElements;
    this.executeElements = fieldType.executeElements;
    this.execution = fieldType.execution;
    this.populateData = fieldType.populateData;
    this.saveData = fieldType.saveData;
    this.mergedDesignElements = {};
    /**
     * Merge the design elements of Form JSON and default field type.
     *
     * @this {FormFieldType}
     * @method mergeDesignElements
     * @param {object} fei Instance of FormExecutorInteraction.
     * @param {object} customDesignElements Design elemnts of Form JSON.
     * @return {object} Resultant object.
     */
    this.mergeDesignElements = function(fei, customDesignElements) {
        var resultantFieldElement = $.extend(true, {}, this.designElements, customDesignElements);
        this.mergedDesignElements[resultantFieldElement.name] = resultantFieldElement;
        return resultantFieldElement;
    };
}

/*
function FormField(name, form, fieldType) {	
	this.init = function(customDesignElements) {
		this.designElements = $.extend(false, fieldType.designElements, customDesignElements);
	};
	this.execute = function() {};
}
*/
//BEHAVIORS-START *** TRIGGER AND ACTION CLASSES//
/**
 * Creates an instance of FormTriggerType.
 *
 * @constructor
 * @this {FormTriggerType}
 * @param {string} name The name of trigger type.
 * @param {function} triggerSetupHandler Trigger setup function(callback) of trigger type.
 * @param {function} formStateHandler The function(callback) of trigger type.
 * @param {function} execHandler Trigger execute function(callback) of trigger type.
 */
function FormTriggerType(name, triggerSetupHandler, formStateHandler, execHandler) {
    this.name = name;
    /** @function */
    this.triggerSetupHandler = triggerSetupHandler;
    /** @function */
    this.formStateHandler = formStateHandler;
    /** @function */
    this.execHandler = execHandler;

    this.setup = function(fei, trigger) {
        this.triggerSetupHandler(this, fei, trigger);
    };
    this.handleFormState = function() {};
    this.execute = function(fei, trigger) {
        this.execHandler(this, fei, trigger);
    };
}

/**
 * Creates an instance of FormTrigger.
 *
 * @constructor
 * @this {FormTrigger}
 * @param {object} triggerType Instance of FormTriggerType.
 * @param {object} params Trigger parameters of Form JSON.
 * @param {object} formActions Instance of FormActions.
 */
function FormTrigger(triggerType, params, formActions) {
    this.triggerType = triggerType;
    this.params = params;
    this.formActions = formActions;

    this.setup = function(fei) {
        if (!fei.listOfAvailableBrowserIdentifiers[this.params.field]) {
            fei.formStructureMessages.addWarning("WMF901", "Trigger field '" + this.params.field + "' mentioned in Trigger '" + this.triggerType.name + "' couldn't find in this form", this.triggerType.name, 4);
        }
        this.triggerType.setup(fei, this);
    };
    this.execute = function(fei) {
        this.triggerType.execute(fei, this);
    };
}

/**
 * Creates an instance of FormTriggers.
 *
 * @constructor
 * @this {FormTriggers}
 * @param {object} fei Instance of FormExecutorInteraction.
 * @param {array} triggerTypes All registered trigger types.
 * @param {object} triggers All triggers of Form JSON.
 * @param {object} field Field definition.
 * @param {array} actionTypes All registered action types.
 * @param {array} actions All actions of Form JSON.
 */
function FormTriggers(fei, triggerTypes, triggers, field, actionTypes, actions) {
    this.triggerTypesUsed = [];
    this.triggers = triggers;
    var ft = this;

    for (var i = 0; i < this.triggers.length; i++) {
        var triggerStruct = this.triggers[i];
        if (triggerTypes[triggerStruct.name]) {
            var triggerFile = (triggerStruct.name).toLowerCase().replace(/ /g, '-');
            var schemaFilePath = fei.allTriggerTypes[triggerFile].path + triggerFile + ".schema.json";
            fei.instanceOfFormInteraction.fetchOrGetCached(schemaFilePath, false, function(schema) {
                //console.log(commonSchema);
                var result = tv4.validateMultiple(triggerStruct, schema);
                //console.log(result);
                // Check result
                if (result.valid) { // Success
                    var formActions = new FormActions(fei, field, actionTypes, actions);
                    var formTrigger = new FormTrigger(triggerTypes[triggerStruct.name], triggerStruct.params, formActions);
                    ft.triggerTypesUsed.push(formTrigger);
                    //if(! this.triggerTypesUsed[triggerStruct.name])
                    //this.triggerTypesUsed[triggerStruct.name] = triggerTypes[triggerStruct.name];
                } else { // Failure
                    fei.formStructureMessages.addErrors(fei.createJsonSchemaMessages(result.errors, "EFJFTS"));
                }
            });
        } else {
            fei.formStructureMessages.addError("EMT803", "Couldn't find a registered Trigger with name " + triggerStruct.name);
        }
    }

    this.setup = function(fei) {
        $.each(this.triggerTypesUsed, function(index, triggerTypeUsed) {
            triggerTypeUsed.setup(fei);
        });
    };

    this.execute = function(fei, trigger) {
        $.each(this.triggerTypesUsed, function(index, triggerTypeUsed) {
            //console.log(triggerTypeUsed);
            triggerTypeUsed.execute(fei, trigger);
        });
    };
}

/**
 * Creates an instance of FormActionType.
 *
 * @constructor
 * @this {FormActionType}
 * @param {string} name The name of action type.
 * @param {function} actionSetupHandler The callback function of action type.
 * @param {function} formStateHandler The callback function of action type.
 * @param {function} execHandler The callback function of action type.
 */
function FormActionType(name, actionSetupHandler, formStateHandler, execHandler) {
    this.name = name;
    this.actionSetupHandler = actionSetupHandler;
    this.formStateHandler = formStateHandler;
    this.execHandler = execHandler;
    this.setup = function(fei, action) {
        this.triggerSetupHandler(this, fei, action);
    };
    this.execute = function(fei, action, trigger) {
        this.execHandler(this, fei, action, trigger);
    };
}

/**
 * Creates an instance of FormAction.
 *
 * @constructor
 * @this {FormAction}
 * @param {object} field Field definition.
 * @param {object} actionType Instance of FormActionType.
 * @param {object} params Action parameters of Form JSON.
 */
function FormAction(field, actionType, params) {
    this.field = field;
    this.actionType = actionType;
    this.params = params;

    if (this.params.field == "$self") {
        this.params.field = field.browserIdentifier;
    }
    if (this.params.options && this.params.options == "$self" && field.option) {
        this.params.options = field.option.options;
    }

    this.setup = function(fei) {
        this.actionType.setup(fei, this);
    };
    this.execute = function(fei, trigger) {
        this.actionType.execute(fei, this, trigger);
    };
}

/**
 * Creates an instance of FormActions.
 *
 * @constructor
 * @this {FormActions}
 * @param {object} fei Instance of FormExecutorInteraction.
 * @param {object} field Field definition.
 * @param {array} actionTypes All registered action types.
 * @param {array} actions All actions of Form JSON.
 */
function FormActions(fei, field, actionTypes, actions) {
    this.actionTypesUsed = [];
    this.actions = actions;
    var fa = this;

    for (var i = 0; i < this.actions.length; i++) {
        var actionStruct = this.actions[i];
        if (actionTypes[actionStruct.name]) {
            var actionFileName = (actionStruct.name).toLowerCase().replace(/ /g, '-');
            var schemaFilePath = fei.allActionTypes[actionFileName].path + actionFileName + ".schema.json";
            fei.instanceOfFormInteraction.fetchOrGetCached(schemaFilePath, false, function(schema) {
                //console.log(commonSchema);
                var result = tv4.validateMultiple(actionStruct, schema);
                //console.log(result);
                // Check result
                if (result.valid) { // Success
                    var formAction = new FormAction(field, actionTypes[actionStruct.name], actionStruct.params);
                    fa.actionTypesUsed.push(formAction);
                    //if(! this.actionTypesUsed[actionStruct.name])
                    //this.actionTypesUsed[actionStruct.name] = actionTypes[actionStruct.name];
                } else { // Failure
                    fei.formStructureMessages.addErrors(fei.createJsonSchemaMessages(result.errors, "EFJFAS"));
                }
            });
        } else {
            fei.formStructureMessages.addError("EMA804", "Couldn't find a registered Action with name " + actionStruct.name);
        }
    }

    this.setup = function(fei) {
        $.each(this.actionTypesUsed, function(index, actionTypeUsed) {
            actionTypeUsed.setup(fei);
        });
    };

    this.execute = function(fei, trigger) {
        $.each(this.actionTypesUsed, function(index, actionTypeUsed) {
            //console.log(actionTypeUsed);
            actionTypeUsed.execute(fei, trigger);
        });
    };
}
//BEHAVIORS-END//

/**
 * Creates an instance of FormDynamicValueType.
 *
 * @constructor
 * @this {FormDynamicValueType}
 * @param {string} name The name of dynamic value type.
 * @param {function} dvSetupHandler The callback function of dynamic value type.
 * @param {function} formStateHandler The callback function of dynamic value type.
 * @param {function} getValueHandler The callback function of dynamic value type.
 */
function FormDynamicValueType(name, dvSetupHandler, formStateHandler, getValueHandler) {
    this.name = name;
    this.dvSetupHandler = dvSetupHandler;
    this.formStateHandler = formStateHandler;
    this.getValueHandler = getValueHandler;
    this.getValue = function(fei, params) {
        return this.getValueHandler(this, fei, params);
    };

    this.registerHandlebarsHelper = function(fei) {
        var hbValueHandlerWrapper = {
            fei: fei,
            dv: this
        };
        Handlebars.registerHelper(this.name, function() {
            return hbValueHandlerWrapper.dv.getValue(hbValueHandlerWrapper.fei, arguments);
        });
    };
}

/**
 * Creates an instance of FormExecutorInteraction.
 *
 * @constructor
 * @this {FormExecutorInteraction}
 */
function FormExecutorInteraction() {
    //Handlebars custom helper class
    new FormHandlebarsHelpers(this);

    //Error message handling
    this.formStructureMessages = new FormMessages();

    //Core class
    this.instanceOfFormInteraction = new FormInteraction();

    this.showBehaviorEventMessages = false;

    //keeps the file path
    this.formsLibBasePath = "../../";
    this.executeTemplateSkin = "standard";
    this.executeTemplateSkinWithSectionBlocks = "wide";
    this.defaultFormPluginURL = "max-forms-exec/src/default.execute.js";
    this.customFieldTypeBasePaths = ["../../"];
    this.coreFieldTypeBasePaths = ["../../max-forms-field-types-core/src/"];
    this.formJsonDSL = {};
    this.preLoadFieldTypes = "*";
    this.initParams = {};

    //this.formTypePaths = ["../../max-forms-form-types-core/src/"];
    this.formTypePaths = ["../../max-forms/max-forms-form-types-core/src/"];
    this.formTypes = {};
    this.addFormType = function(formType) {
        this.formTypes[formType.name] = formType;
        //console.log(this.formTypes);
    };

    this.formFieldTypes = {};
    this.addFieldType = function(fieldType) {
        this.formFieldTypes[fieldType.name] = fieldType;
    };

    //BEHAVIORS-START ***** STORING THE REGISTERED TRIGGERS AND ACTIONS//
    this.triggerTypePaths = ["../../max-forms-trigger-types-core/src/"];
    this.actionTypePaths = ["../../max-forms-action-types-core/src/"];

    this.triggerTypes = {};
    this.actionTypes = {};

    this.addTriggerType = function(trigger) {
        // add action to this.formTriggers 
        this.triggerTypes[trigger.name] = trigger;
        //console.log(trigger);
    };
    this.addActionType = function(action) {
        // add action to this.formActions 
        this.actionTypes[action.name] = action;
        //console.log(action);
    };
    //BEHAVIORS-END//

    //DYNAMIC VALUE-START ***** DECALARING BASIC DYNAMIC VALUE TYPE PATHS//
    this.dynamicValueTypePaths = ["../../max-forms-dynamic-value-types-core/src/"];
    this.dynamicValueTypes = {};

    this.addDynamicValueType = function(dv) {
        this.dynamicValueTypes[dv.name] = dv;
        dv.registerHandlebarsHelper(this);
    };
    //DYNAMIC VALUE-END//

    this.executorBaseDiv = {
        name: "build",
        id: "#build",
        classSelector: ""
    };

    var fei = this;

    /**
     * Overwrite all the dafault configurations and setup the execution.
     *
     * @this {FormExecutorInteraction}
     * @method fetchFormTemplateJson
     * @param {object} params Custom configurations.
     * @param {function} callback Callback function.
     */
    this.fetchFormTemplateJson = function(params, callback) {
        var pathname = window.location.hash.split("?");
        if (params.formJson || params.formJsonSourceURL) {
            var properFormJson;
            if (params.formJson) {
                properFormJson = params.formJson;
            } else if (params.formJsonSourceURL) {
                // like gsa-frpp
                this.instanceOfFormInteraction.fetchData(params.formJsonSourceURL, false, function(formJson) {
                    if (formJson.hasOwnProperty('results')) {
                        // Handle parsing out results of object returned from RESTful services.
                        properFormJson = formJson.results;
                    } else {
                        properFormJson = formJson;
                    }
                });
            }

            if (params.formJsonData) {
                properFormJson.data = params.formJsonData;
            } else if (params.formJsonDataSourceURL) {
                this.instanceOfFormInteraction.fetchData(params.formJsonDataSourceURL, false, function(formJsonData) {
                    properFormJson.data = formJsonData;
                });
            }

            callback(properFormJson);
        }
        /*else if(pathname[1]) {                        
			// if the call is from the 'Form Templates' page, we need to tak the datas from the persistence. The parameters will be in the url.
			// if the fourth parameter is greater than 50 chars treat as data, else treat as the form sheet name
			var apiPaths = pathname[1].split("&");
			if (apiPaths[2] && apiPaths[3] && apiPaths[3].length > 50 && this.refFormDataPersistence) {				
                                console.log('inside first');
                                this.refFormDataPersistence.getFormData({library:apiPaths[0], scope:apiPaths[1], formName:apiPaths[2], data:apiPaths[3]}, function(JsonString){
					callback(JsonString);
				});      
			} else if(apiPaths[2] && (!apiPaths[3] || apiPaths[3] && apiPaths[3].length < 50) && this.refFormTemplatePersistence){				
                params = {library:apiPaths[0], scope:apiPaths[1], formName:apiPaths[2]};
				if (apiPaths[3])	{
					params['sheetName'] = apiPaths[3];
				}
                console.log('========================');
                console.log(JSON.stringify(params));
				this.refFormTemplatePersistence.getFormTemplate(params, function(JsonString) {
					console.log('json string');
                    console.log(JSON.stringify(JsonString));
                    callback(JsonString);                       
				});      
			}
		}*/
    };

    this.getThisElementIdentifier = function(fieldId, indexCount) {
        return "#field-container-" + fieldId + "-" + indexCount;
    };
    this.getDefaultFormPluginURL = function() {
        return this.formsLibBasePath + this.defaultFormPluginURL;
    };
    this.getFormTemplateSourceURL = function(formTypeName) {
        return this.allFormTypes[formTypeName].path + formTypeName + ".execute.js.html";
        /*if(form.formType) {
			var executeTemplateSkin = form.formType?form.formType:formJson.sectionBlocks?this.executeTemplateSkinWithSectionBlocks:this.executeTemplateSkin;
			return this.formsLibBasePath + this.coreFormTemplatePath + "templates/skin-" + executeTemplateSkin + "/" + executeTemplateSkin + ".execute.js.html";
		}
		else if(this.formTemplateSourceURL){
			return this.formTemplateSourceURL;
		}*/
    };
    this.getFormSchemaSourceURL = function(formTypeName) {
        return this.allFormTypes[formTypeName].path + formTypeName + ".schema.json";
    };
    /*this.getFormPluginSourceURL = function() {
		return this.formsLibBasePath + this.coreFormTemplatePath + "src/max-forms-formeditor.js";
	};*/
    this.getFieldTypeBasePath = function(fieldTypeName) {
        return this.allFieldTypes[fieldTypeName].path;
    };
    this.getCommonSchemaUrl = function(schema) {
        return schema.properties.fieldElement.id; //this.formsLibBasePath + "max-forms-core/src/max-forms-field-type-common.schema.json";//
    };
    this.getFormTypeName = function(formJson) {
        var formTypeName;
        if (formJson.form.formType) {
            formTypeName = formJson.form.formType;
        } else {
            if (formJson.sectionBlocks && formJson.sectionBlocks.length) {
                formTypeName = this.executeTemplateSkinWithSectionBlocks;
            } else {
                formTypeName = this.executeTemplateSkin;
            }
            formJson.form.formType = formTypeName; //Set the appropriate form type to form json if already not set.
        }
        return formTypeName;
    };

    /**
     * Overwrite all the dafault confugarations and setup the execution.
     *
     * @this {FormExecutorInteraction}
     * @method init
     * @param {object} params Custom configurations.
     */
    this.init = function(params) {
        this.initParams = $.extend(true, {}, params);
        //Check for whether persistence is needed or not
        if (params.needPersistenceLibraries) {
            this.refFormTemplatePersistence = new FormTemplatesPersistence(params.persistenceBaseUrl ? params.persistenceBaseUrl : PERSISTENCE_BASE_URL, this.instanceOfFormInteraction);
            this.refFormDataPersistence = new FormDataPersistence(params.persistenceBaseUrl ? params.persistenceBaseUrl : PERSISTENCE_BASE_URL, this.instanceOfFormInteraction);
        }
        // assign params to proper this.* variables	
        FDI_JQUERY_LOAD_PLUGIN_SELECTOR = this.executorBaseDiv.id;
        $('.ajax-throbbing').show();
        if (params.formsLibBasePath) {
            this.formsLibBasePath = params.formsLibBasePath;
        }
        if (params.customFieldTypeBasePaths) {
            this.customFieldTypeBasePaths = params.customFieldTypeBasePaths;
        }
        if (params.defaultFormPluginURL) {
            this.defaultFormPluginURL = params.defaultFormPluginURL;
        }
        if (params.formTypePaths) {
            this.formTypePaths = params.formTypePaths;
        }
        if (params.coreFieldTypeBasePaths) {
            this.coreFieldTypeBasePaths = params.coreFieldTypeBasePaths;
        }
        if (params.preLoadFieldTypes) {
            this.preLoadFieldTypes = params.preLoadFieldTypes;
        }
        if (params.triggerTypePaths) {
            this.triggerTypePaths = params.triggerTypePaths;
        }
        if (params.actionTypePaths) {
            this.actionTypePaths = params.actionTypePaths;
        }
        if (params.dynamicValueTypePaths) {
            this.dynamicValueTypePaths = params.dynamicValueTypePaths;
        }

        if (params.onTemplateLoadSuccess) {
            this.onTemplateLoadSuccess = params.onTemplateLoadSuccess;
        }
        if (params.onTemplateLoadFailure) {
            this.onTemplateLoadFailure = params.onTemplateLoadFailure;
        }
        if (params.onSubmitSuccess) {
            this.onSubmitSuccess = params.onSubmitSuccess;
        }
        if (params.onSubmitFailure) {
            this.onSubmitFailure = params.onSubmitFailure;
        }
        if (params.onTemplateLoadWarnings) {
            this.onTemplateLoadWarnings = params.onTemplateLoadWarnings;
        }
        if (params.showBehaviorEventMessages) {
            this.showBehaviorEventMessages = params.showBehaviorEventMessages;
        }
        if (params.setAsyncProgressNotifier) {
            this.instanceOfFormInteraction.setAsyncProgressNotifier = params.setAsyncProgressNotifier;
        }

        //***** REGISTERING FORM TYPES//
        var formTypePromise = this.registerFormTypes(this.formTypePaths);

        //***** REGISTERING BASIC AND CUSTOM FIELDS//
        var fieldTypePromise = this.registerFieldTypes();
        //***** REGISTERING BASIC AND CUSTOM FIELDS-END//

        
        //BEHAVIORS-START ***** REGISTERING BASIC TRIGGERS AND ACTIONS//
        var triggerTypePromise = fei.registerTriggerTypes(fei.triggerTypePaths);
        var actionTypePromise = fei.registerActionTypes(fei.actionTypePaths);
        //BEHAVIORS-END//

        //DYNAMIC VALUE-START ***** REGISTERING BASIC DYNAMIC VALUE TYPES//
        var dynamicValueTypePromise = fei.registerDynamicValueTypes(fei.dynamicValueTypePaths);
        //DYNAMIC VALUE-END//

        setTimeout(function() {
            // time out the promises after 15 seconds
            if(formTypePromise.state() == 'pending') {
                formTypePromise.reject();
                console.error("MAX Forms 'form types' loading promise timed out");
            }
            if(fieldTypePromise.state() == 'pending') {
                fieldTypePromise.reject();
                console.error("MAX Forms 'field types' loading promise timed out");
            }
            if(triggerTypePromise.state() == 'pending') {
                triggerTypePromise.reject();
                console.error("MAX Forms 'trigger types' loading promise timed out");
            }
            if(actionTypePromise.state() == 'pending') {
                actionTypePromise.reject();
                console.error("MAX Forms 'action types' loading promise timed out");
            }
            if(dynamicValueTypePromise.state() == 'pending') {
                dynamicValueTypePromise.reject();
                console.error("MAX Forms 'dynamic value types' loading promise timed out");
            }
        }, 25000);

        $.when(formTypePromise, fieldTypePromise, triggerTypePromise, actionTypePromise, dynamicValueTypePromise).always(function() {
            if (params.executeTemplateSkin) {
                fei.executeTemplateSkin = params.executeTemplateSkin;
            }
            if (params.executeTemplateSkinWithSectionBlocks) {
                fei.executeTemplateSkinWithSectionBlocks = params.executeTemplateSkinWithSectionBlocks;
            }

            fei.generateForm(params);
        });
    };
    /**
     * Initiate the execution of a form.
     * This function can be called independently and handles the following object keys- executionParameters, formJson, formJsonSourceURL, formJsonData, formJsonDataSourceURL, onTemplateLoadSuccess, onTemplateLoadFailure, onSubmitSuccess, onSubmitFailure, onTemplateLoadWarnings.
     * @this {FormExecutorInteraction}
     * @method generateForm
     * @param {object} params Custom configurations.
     */
    this.generateForm = function(params) {
        if (params.onTemplateLoadSuccess) {
            this.onTemplateLoadSuccess = params.onTemplateLoadSuccess;
        }
        if (params.onTemplateLoadFailure) {
            this.onTemplateLoadFailure = params.onTemplateLoadFailure;
        }
        if (params.onSubmitSuccess) {
            this.onSubmitSuccess = params.onSubmitSuccess;
        }
        if (params.onSubmitFailure) {
            this.onSubmitFailure = params.onSubmitFailure;
        }
        if (params.onTemplateLoadWarnings) {
            this.onTemplateLoadWarnings = params.onTemplateLoadWarnings;
        }

        //Clears all the previous errors and warnings before the execution starts
        this.formStructureMessages.clearAllErrorsAndWarnings();

        this.fetchFormTemplateJson(params, function(JsonString) {
            fei.formJsonDSL = JsonString;
            //setTimeout(function(){
            if (params.executionParameters) {
                jQuery(FDI_JQUERY_LOAD_PLUGIN_SELECTOR).loadExecutionForm(params.executionParameters);
            } else {
                fei.instanceOfFormInteraction.fetchFile(fei.getDefaultFormPluginURL());
            }
            //}, 100);
        });
    };
    /**
     * Generates the JSON schema of Form DSL.
     *
     * @this {FormExecutorInteraction}
     * @method generateFormStructureSchema
     * @param {object} params Custom configurations.
     */
    this.generateFormStructureSchema = function(params, callback) {
        //Clears all the previous errors and warnings before the execution starts
        this.formStructureMessages.clearAllErrorsAndWarnings();

        this.fetchFormTemplateJson(params, function(formJson) {
            fei.validateFormType(formJson, function() {
                var formJsonDataStructSchema = {
                    "type": "object",
                    "properties": {},
                    "required": []
                };
                if (formJson.sectionBlocks && formJson.sectionBlocks.length) {
                    jQuery.each(formJson.sectionBlocks, function(k, formJsonBlock) {
                        jQuery.each(formJsonBlock.fieldsets, function(j, formJsonFieldset) {
                            jQuery.each(formJsonFieldset.elements, function(i, field) {
                                fei.validateFieldType(field, function() {
                                    var instanceOfFieldType = fei.formFieldTypes[field.fieldType];
                                    if (instanceOfFieldType.saveData && instanceOfFieldType.saveData.addToStructJsonSchema) {
                                        var fieldDefinition = instanceOfFieldType.mergeDesignElements(fei, field.fieldElement);
                                        instanceOfFieldType.saveData.addToStructJsonSchema(fei, instanceOfFieldType, fieldDefinition, formJsonDataStructSchema, fieldDefinition.name);
                                    } else {
                                        fei.formStructureMessages.addError("EMCBF809", "The field type '" + instanceOfFieldType.name + "' not contains 'saveData.addToStructJsonSchema' section");
                                    }
                                });
                            });
                        });
                    });
                } else {
                    jQuery.each(formJson.elements, function(i, field) {
                        fei.validateFieldType(field, function() {
                            var instanceOfFieldType = fei.formFieldTypes[field.fieldType];
                            if (instanceOfFieldType.saveData && instanceOfFieldType.saveData.addToStructJsonSchema) {
                                var fieldDefinition = instanceOfFieldType.mergeDesignElements(fei, field.fieldElement);
                                instanceOfFieldType.saveData.addToStructJsonSchema(fei, instanceOfFieldType, fieldDefinition, formJsonDataStructSchema, fieldDefinition.name);
                            } else {
                                fei.formStructureMessages.addError("EMCBF809", "The field type '" + instanceOfFieldType.name + "' not contains 'saveData.addToStructJsonSchema' section");
                            }
                        });
                    });
                }

                if (fei.formStructureMessages.hasErrors()) {
                    fei.formStructureMessages.alertErrors();
                } else {
                    callback(formJsonDataStructSchema);
                }
            }, function() {
                fei.formStructureMessages.alertErrors();
            });
        });
    };
    /**
     * Generates the Html Schema documentation of Form DSL.
     *
     * @this {FormExecutorInteraction}
     * @method generateSchemaHtmlDocumentation
     * @param {object} params Custom configurations.
     */
    this.generateSchemaHtmlDocumentation = function(params, callback) {
        this.generateFormStructureSchema(params, function(schema) {
            var path = fei.formsLibBasePath + "max-forms-support/template/schema.json.generated.html";
            fei.instanceOfFormInteraction.fetchData(path, true, function(template) {
                var hbsTemplate = Handlebars.compile(template);
                var html = hbsTemplate(schema);
                callback(html);
            });
        });
    };
    /**
     * Registers all form types.
     *
     * @this {FormExecutorInteraction}
     * @method registerFormTypes
     * @param {array} formTypePaths Paths of form type.
     */
    this.registerFormTypes = function(formTypePaths) {
        var outerPromise = $.Deferred();

        this.allFormTypes = {};
        var customParams = {
            loadPlugin: false
        };
        var registrationPromises = fei.instanceOfFormInteraction.registerTypes(formTypePaths, this.allFormTypes, customParams);

        //Load the form plugins
        /*if($.isArray(this.preLoadFormTypes)){
			$.each(this.preLoadFormTypes, function(i, formType){
				fei.instanceOfFormInteraction.appendFileToHeadTag(fei.allFormTypes[formType].path + formTypeName + ".js");									
			});
		}else{
			for(formType in this.allFormTypes){
				fei.instanceOfFormInteraction.appendFileToHeadTag(this.allFormTypes[formType].path + formTypeName + ".js");									
			}
		}*/

        // Wait until all 'register' ajax calls are finished
        $.when.apply($, registrationPromises).always(function () {

            var fetchPromises = [];

            for(formType in fei.allFormTypes) {
                var fetchPromise = fei.instanceOfFormInteraction.fetchFile(fei.allFormTypes[formType].path + formType + ".js");
                fetchPromises.push(fetchPromise);                                
            }

            // Wait until all form types are loaded
            $.when.apply($, fetchPromises).always(function () {
                outerPromise.resolve();
            });
        });

        setTimeout(function() {
            // time out the registration promises after 15 seconds
            for(var i = 0; i < registrationPromises.length; i++) {
                if(registrationPromises[i].state() == 'pending') {
                    registrationPromises[i].reject();
                    console.error("MAX Forms 'form types' registration promise timed out");
                }
            }
        }, 15000);

        return outerPromise;
    };
    /**
     * Registers all field types.
     *
     * @this {FormExecutorInteraction}
     * @method registerFieldTypes
     */
    this.registerFieldTypes = function() {
        var outerPromise = $.Deferred();

        this.allFieldTypes = {};
        var customParams = {};

        var registerCorePromises = fei.instanceOfFormInteraction.registerTypes(this.coreFieldTypeBasePaths, this.allFieldTypes, customParams);

        customParams = {
            filter: "a[href^='max-forms-field-type-']",
            subTypeFolderReplace: "max-forms-field-type-",
            itemType: "custom"
        };
        var registerCustomPromises = fei.instanceOfFormInteraction.registerTypes(this.customFieldTypeBasePaths, this.allFieldTypes, customParams);

        var registrationPromises = [].concat(registerCorePromises, registerCustomPromises);

        // Wait until all 'register' ajax calls are finished
        $.when.apply($, registrationPromises).always(function () {

            var fetchPromises = [];
            
            //Load the form field plugins
            if ($.isArray(fei.preLoadFieldTypes)) {
                $.each(fei.preLoadFieldTypes, function(i, fieldType) {
                    var fetchPromise = fei.instanceOfFormInteraction.fetchFile(fei.allFieldTypes[fieldType].path + fieldType + ".js");
                });
            } else if (fei.preLoadFieldTypes === "core") {
                for (var cFieldType in fei.allFieldTypes) {
                    if (fei.allFieldTypes[cFieldType].type === "core") {
                        var fetchPromise = fei.instanceOfFormInteraction.fetchFile(fei.allFieldTypes[cFieldType].path + cFieldType + ".js");
                    }
                }
            } else if (fei.preLoadFieldTypes === "custom") {
                for (var bFieldType in fei.allFieldTypes) {
                    if (fei.allFieldTypes[bFieldType].type === "custom") {
                        var fetchPromise = fei.instanceOfFormInteraction.fetchFile(fei.allFieldTypes[bFieldType].path + bFieldType + ".js");
                    }
                }
            } else {
                for (var aFieldType in fei.allFieldTypes) {
                    var fetchPromise = fei.instanceOfFormInteraction.fetchFile(fei.allFieldTypes[aFieldType].path + aFieldType + ".js");
                    fetchPromises.push(fetchPromise);
                }
            }

            // Wait until all form fields are loaded
            $.when.apply($, fetchPromises).always(function () {
                outerPromise.resolve();
            });

        });

        setTimeout(function() {
            // time out the registration promises after 15 seconds
            for(var i = 0; i < registrationPromises.length; i++) {
                if(registrationPromises[i].state() == 'pending') {
                    registrationPromises[i].reject();
                    console.error("MAX Forms 'field types' registration promise timed out");
                }
            }
        }, 15000);

        return outerPromise;
    };
    /**
     * Registers all trigger types.
     *
     * @this {FormExecutorInteraction}
     * @method registerTriggerTypes
     * @param {array} triggerTypePaths Paths of trigger type.
     */
    this.registerTriggerTypes = function(triggerTypePaths) {
        var outerPromise = $.Deferred();

        this.allTriggerTypes = {};
        var customParams = {
            loadPlugin: false
        };
        var registrationPromises = fei.instanceOfFormInteraction.registerTypes(triggerTypePaths, this.allTriggerTypes, customParams);

        // Wait until all 'register' ajax calls are finished
        $.when.apply($, registrationPromises).always(function () {

            var fetchPromises = [];

            for(triggerType in fei.allTriggerTypes) {
                var fetchPromise = fei.instanceOfFormInteraction.fetchFile(fei.allTriggerTypes[triggerType].path + triggerType + ".js");
                fetchPromises.push(fetchPromise);                                
            }

            // Wait until all form types are loaded
            $.when.apply($, fetchPromises).always(function () {
                outerPromise.resolve();
            });
        });

        setTimeout(function() {
            // time out the registration promises after 15 seconds
            for(var i = 0; i < registrationPromises.length; i++) {
                if(registrationPromises[i].state() == 'pending') {
                    registrationPromises[i].reject();
                    console.error("MAX Forms 'trigger types' registration promise timed out");
                }
            }
        }, 15000);

        return outerPromise;
    };

    /**
     * Registers all action types.
     *
     * @this {FormExecutorInteraction}
     * @method registerActionTypes
     * @param {array} actionTypePaths Paths of action type.
     */
    this.registerActionTypes = function(actionTypePaths) {
        var outerPromise = $.Deferred();

        this.allActionTypes = {};
        var customParams = {
            loadPlugin: false
        };
        var registrationPromises = fei.instanceOfFormInteraction.registerTypes(actionTypePaths, this.allActionTypes, customParams);

        // Wait until all 'register' ajax calls are finished
        $.when.apply($, registrationPromises).always(function () {

            var fetchPromises = [];

            for(actionType in fei.allActionTypes) {
                var fetchPromise = fei.instanceOfFormInteraction.fetchFile(fei.allActionTypes[actionType].path + actionType + ".js");
                fetchPromises.push(fetchPromise);                                
            }

            // Wait until all form types are loaded
            $.when.apply($, fetchPromises).always(function () {
                outerPromise.resolve();
            });
        });

        setTimeout(function() {
            // time out the registration promises after 15 seconds
            for(var i = 0; i < registrationPromises.length; i++) {
                if(registrationPromises[i].state() == 'pending') {
                    registrationPromises[i].reject();
                    console.error("MAX Forms 'action types' registration promise timed out");
                }
            }
        }, 15000);

        return outerPromise;
    };
    /**
     * Registers all dynamic value types.
     *
     * @this {FormExecutorInteraction}
     * @method registerDynamicValueTypes
     * @param {array} dynamicValueTypePaths Paths of dynamic value type.
     */
    this.registerDynamicValueTypes = function(dynamicValueTypePaths) {
        var outerPromise = $.Deferred();

        this.allDynamicValueTypes = {};
        var customParams = {
            loadPlugin: false
        };
        var registrationPromises = fei.instanceOfFormInteraction.registerTypes(dynamicValueTypePaths, this.allDynamicValueTypes, customParams);

        // Wait until all 'register' ajax calls are finished
        $.when.apply($, registrationPromises).always(function () {

            var fetchPromises = [];

            for(dynamicValueType in fei.allDynamicValueTypes) {
                var fetchPromise = fei.instanceOfFormInteraction.fetchFile(fei.allDynamicValueTypes[dynamicValueType].path + dynamicValueType + ".js");
                fetchPromises.push(fetchPromise);                                
            }

            // Wait until all form types are loaded
            $.when.apply($, fetchPromises).always(function () {
                outerPromise.resolve();
            });
        });

        setTimeout(function() {
            // time out the registration promises after 15 seconds
            for(var i = 0; i < registrationPromises.length; i++) {
                if(registrationPromises[i].state() == 'pending') {
                    registrationPromises[i].reject();
                    console.error("MAX Forms 'dynamic value types' registration promise timed out");
                }
            }
        }, 15000);

        return outerPromise;
    };
    /**
     * Checks the existance of an Id.
     *
     * @this {FormExecutorInteraction}
     * @method idExists
     * @param {array} objectArray array of objects
     * @param {string} id Id of field
     * @return {boolean} Existance of an Id
     */
    this.idExists = function(objectArray, id) {
        var found = false;
        $.each(objectArray, function(i, v) {
            if (v.id == id) {
                found = true;
            }
        });
        return found;
    };
    /**
     * Creates error messages for JSON schema validation.
     *
     * @this {FormExecutorInteraction}
     * @method createJsonSchemaMessages
     * @param {array} jsonSchemaErrors Json Schema Errors
     * @return {array} Returns error messages
     */
    this.createJsonSchemaMessages = function(jsonSchemaErrors, categoryCode, optionalDataPath) {
        var result = [];
        var thisCategoryCode = categoryCode ? categoryCode : "EJS";
        $.each(jsonSchemaErrors, function(index, error) {
            //console.log(error);
            //var dataPath = error.dataPath?error.dataPath:optionalDataPath?optionalDataPath:"field";
            var dataPath = optionalDataPath ? (optionalDataPath + error.dataPath) : error.dataPath ? error.dataPath : "field";
            result.push(new FormMessage(thisCategoryCode + error.code, error.message, dataPath, null));
        });
        return result;
    };
    /**
     * Add error messages for logical errors on fields.
     *
     * @this {FormExecutorInteraction}
     * @method addFieldStructureError
     * @param {string} message Error message
     * @param {string} errorCode Optional error code
     */
    this.addFieldStructureError = function(message, errorCode) {
        this.formStructureMessages.addError(errorCode ? errorCode : "ELFAV805", message);
    };
    /**
     * Add error messages for 508 compliance errors on form.
     *
     * @this {FormExecutorInteraction}
     * @method addSection508ComplianceError
     * @param {string} message Error message
     * @param {string} errorCode Optional error code
     */
    this.addSection508ComplianceError = function(message, errorCode) {
        fei.formStructureMessages.addError(errorCode ? errorCode : "E508", message);
    };
    /**
     * Add warning messages for 508 compliance errors on form.
     *
     * @this {FormExecutorInteraction}
     * @method addSection508ComplianceWarning
     * @param {string} message Warning message
     * @param {string} errorCode Optional error code
     */
    this.addSection508ComplianceWarning = function(message, errorCode) {
        fei.formStructureMessages.addWarning(errorCode ? errorCode : "W508", message);
    };
    /**
     * Starts execution of the Form.
     *
     * @this {FormExecutorInteraction}
     * @method executionFormLoadTemplateSrc
     * @param {object} controls Contains fei, details of form etc.
     */
    this.executionFormLoadTemplateSrc = function(controls) {
        //BEHAVIORS-START **** DECLARE ARRAY TO STORE MATCHED TRIGGERS AND ACTIONS//
        this.listOfTriggers = [];
        this.listOfInitialActions = [];
        //BEHAVIORS-END//

        this.listOfAvailableBrowserIdentifiers = {};

        fei.formBlockElementCreation(controls, function() {
            //console.log(controls);
            if (!fei.formStructureMessages.hasErrors()) {
                //BEHAVIORS-START//
                //FIRE DEFAULT BEHAVIORS ***ITERATE MATCHED ACTIONS AND CALL THE EXECUTEHANDLER
                //setTimeout(function() {
                $.each(fei.listOfInitialActions, function(index, initialActions) {
                    //console.log(initialActions);
                    initialActions.execute(fei, false);
                });
                //}, 1);

                //ITERATE MATCHED TRIGGERS AND CALL THE SETUPHANDLER
                $.each(fei.listOfTriggers, function(index, triggers) {
                    //console.log(triggers);
                    triggers.setup(fei);
                });
                //BEHAVIORS-END//

                if (fei.formStructureMessages.hasWarnings()) {
                    fei.onTemplateLoadWarnings(fei, fei.formStructureMessages);
                }

                $("#" + controls.f.designElements.designerID).show();
                if (fei.onTemplateLoadSuccess) {
                    fei.onTemplateLoadSuccess(fei, fei.formId);
                }

                //MaxForms Validation trigger
                // Disable running validations after form generation.
                // This should be reserved until someone submits the form.
                // Make this configurable if it needs to be re-enabled.
                //$(fei.formId).maxForms('validate');
            } else {
                if (fei.onTemplateLoadFailure) {
                    fei.onTemplateLoadFailure(fei, fei.formStructureMessages);
                }
            }
        });
    };
    /**
     * Validates Form and checks whether the Form JSON contains section blocks or not etc.
     *
     * @this {FormExecutorInteraction}
     * @method formBlockElementCreation
     * @param {object} controls Contains fei, details of form etc.
     * @param {function} callback Callback function.
     */
    this.formBlockElementCreation = function(controls, callback) {
        var formJson = controls.fee;
        this.validateFormType(formJson, function() {
            var formTypeName = fei.getFormTypeName(formJson);
            fei.instanceOfFormInteraction.fetchData(fei.getFormTemplateSourceURL(formTypeName), false, function(template) {
                var instanceOfFormType = fei.formTypes[formTypeName];
                //Merge the Form custom design elements with Form plugin design elements- START
                var resultantFormElement = instanceOfFormType.mergeDesignElements(fei, formJson.form);
                formJson.form = resultantFormElement;
                //Merging-END
                fei.formId = "#" + resultantFormElement.formId.replace(/\./g, '\\.');

                //console.log(resultantFormElement)
                formJson.clientPersistData = false;
                if (resultantFormElement.persistInClient && fei.getFormClientPersistedData(resultantFormElement.name)) {
                    formJson.clientPersistData = fei.getFormClientPersistedData(resultantFormElement.name);
                }

                var hbsTemplate = Handlebars.compile(template);
                var html = hbsTemplate(controls);
                //Dymamic value replacement for form fields caption- START
                var hbsTemplateForDynamicValueReplacement = Handlebars.compile(html);
                html = hbsTemplateForDynamicValueReplacement();
                // Run replacement again to catch any nested dynamic values
                hbsTemplateForDynamicValueReplacement = Handlebars.compile(html);
                html = hbsTemplateForDynamicValueReplacement();
                $("#" + controls.f.designElements.designerID).hide();
                $("#" + controls.f.designElements.designerID).html(html);
                //Dymamic value replacement for form fields caption- END
                if (formJson.sectionBlocks && formJson.sectionBlocks.length) {
                    jQuery.each(formJson.sectionBlocks, function(k, formJsonBlock) {
                        jQuery.each(formJsonBlock.fieldsets, function(j, formJsonFieldset) {
                            formJsonFieldset.data = formJson.data;
                            formJsonFieldset.form = resultantFormElement;
                            fei.formElementCreation(formJsonFieldset, k);
                        });
                    });
                } else {
                    fei.formElementCreation(formJson, 0);
                }
                callback();
            });
        }, callback);
    };
    /**
     * Validates the form Json.
     *
     * @this {FormExecutorInteraction}
     * @method validateFormType
     * @param {object} formJson
     * @param {function} successCallback Success callback function.
     * @param {function} failureCallback Failure callback function.
     */
    this.validateFormType = function(formJson, successCallback, failureCallback) {
        var formTypeName = fei.getFormTypeName(formJson);
        var instanceOfFormType = fei.formTypes[formTypeName];
        if (instanceOfFormType) {
            this.instanceOfFormInteraction.fetchOrGetCached(this.getFormSchemaSourceURL(formTypeName), true, function(schema) {
                var result = tv4.validateMultiple(formJson, schema);
                //console.log(result);
                // Check result
                if (result.valid) {
                    successCallback();
                } else { // Failure
                    fei.formStructureMessages.addErrors(fei.createJsonSchemaMessages(result.errors, "EFJS"));
                    if (failureCallback) {
                        failureCallback();
                    }
                }
            });
        } else {
            fei.formStructureMessages.addError("EMFT801", "Couldn't find a registered Form Type with name " + formTypeName);
            if (failureCallback) {
                failureCallback();
            }
        }
    };

    this.cachedSchema = {};
    /**
     * Adds(if not already added) a common schema for TV4 execution.
     *
     * @this {FormExecutorInteraction}
     * @method addOrCacheSchema
     * @param {string} url Url of common schema
     * @param {object} commonSchema Schema of common attributes
     */
    this.addOrCacheSchema = function(url, commonSchema) {
        if (!this.cachedSchema[url]) {
            tv4.addSchema(url, commonSchema);
            this.cachedSchema[url] = commonSchema;
        }
    };
    /**
     * Iterates the form fields and call the execution function after validation
     *
     * @this {FormExecutorInteraction}
     * @method formElementCreation
     * @param {object} formJson Form JSON DSL
     * @param {number} k count of fields
     */
    this.formElementCreation = function(formJson, k) {
        var elementIdentifier;
        jQuery.each(formJson.elements, function(i, field) {
            var instanceOfFieldType = fei.formFieldTypes[field.fieldType];
            elementIdentifier = fei.getThisElementIdentifier(field.fieldElement.browserIdentifier, k);
            fei.listOfAvailableBrowserIdentifiers[field.fieldElement.name] = field.fieldElement.browserIdentifier;
            fei.validateFieldType(field, function() {
                if (instanceOfFieldType.execution && instanceOfFieldType.execution.createHtmlMarkup) {
                    var resultantFieldElement = instanceOfFieldType.mergeDesignElements(fei, field.fieldElement);
                    var validateSection508ComplianceBehaviour = formJson.form.validateSection508Compliance;
                    var doExecuteField = true;
                    if (validateSection508ComplianceBehaviour && validateSection508ComplianceBehaviour != "none" && instanceOfFieldType.validateElements) {
                        if (instanceOfFieldType.validateElements.validateSection508Compliance) {
                            if (validateSection508ComplianceBehaviour == "strict") {
                                doExecuteField = instanceOfFieldType.validateElements.validateSection508Compliance(fei, instanceOfFieldType, resultantFieldElement, formJson.data, fei.addSection508ComplianceError);
                            } else if (validateSection508ComplianceBehaviour == "warn") {
                                instanceOfFieldType.validateElements.validateSection508Compliance(fei, instanceOfFieldType, resultantFieldElement, formJson.data, fei.addSection508ComplianceWarning);
                            }
                        } else {
                            fei.formStructureMessages.addWarning("WMCBF902", "The field type '" + instanceOfFieldType.name + "' not contains 'validateElements.validateSection508Compliance' section");
                        }
                    }

                    if (doExecuteField) {
                        instanceOfFieldType.execution.createHtmlMarkup(fei, instanceOfFieldType, resultantFieldElement, field.fieldElement, formJson.data, elementIdentifier, formJson.clientPersistData);
                    }
                } else {
                    fei.formStructureMessages.addError("EMCBF809", "The field type '" + instanceOfFieldType.name + "' not contains 'execution.createHtmlMarkup' section");
                }
            });
        });
    };
    /**
     * Validates the field type.
     *
     * @this {FormExecutorInteraction}
     * @method validateFieldType
     * @param {object} fieldJson
     * @param {function} callback Callback function.
     */
    this.validateFieldType = function(fieldJson, callback) {
        if (fei.formFieldTypes[fieldJson.fieldType]) {
            var schemaUrl = this.getFieldTypeBasePath(fieldJson.fieldType) + fieldJson.fieldType + ".schema.json";
            this.instanceOfFormInteraction.fetchOrGetCached(schemaUrl, false, function(schema) {
                if (typeof schema == 'string' || schema instanceof String) {
                    schema = JSON.parse(schema); // schema is coming back as string, so parse it
                }
                var commonSchemaUrl = fei.getCommonSchemaUrl(schema);
                fei.instanceOfFormInteraction.fetchOrGetCached(commonSchemaUrl, false, function(commonSchema) {
                    //console.log(commonSchema);
                    fei.addOrCacheSchema(commonSchemaUrl, commonSchema);
                    var result = tv4.validateMultiple(fieldJson, schema);
                    //console.log(result);
                    // Check result
                    if (result.valid) { //Success
                        callback();
                    } else { // Failure
                        fei.formStructureMessages.addErrors(fei.createJsonSchemaMessages(result.errors, "EFJFS"));
                    }
                });
            });
        } else {
            fei.formStructureMessages.addError("EMFT802", "Couldn't find a registered Field Type with name " + fieldJson.fieldType);
        }
    };
    /**
     * Process the field execution
     *
     * @this {FormExecutorInteraction}
     * @method attributeControlExecutionTemplateSrc
     * @param {object} instanceOfFieldType Instance of field type
     * @param {object} resultantFieldElement field definition
     * @param {object} formData Saved data of the Form JSON
     * @param {string} elementIdentifier Id of field
     * @param {object} formClientPersistData Client persist data of the Form JSON
     */
    this.attributeControlExecutionTemplateSrc = function(instanceOfFieldType, resultantFieldElement, formData, elementIdentifier, formClientPersistData) {
        //console.log(resultantFieldElement);
        var controls = {
            "ec": resultantFieldElement,
            "ee": resultantFieldElement
        };

        //Field Structure validation					
        if (instanceOfFieldType.validateElements.fieldStructure) {
            instanceOfFieldType.validateElements.fieldStructure(fei, instanceOfFieldType, resultantFieldElement);
        }

        //Auto field validation- maxFormsValidator
        if (instanceOfFieldType.validateElements.autoValidateData) {
            window.maxFormsConfig = $.extend(true, {}, window.maxFormsConfig, instanceOfFieldType.validateElements.autoValidateData);
            //console.log(window.maxFormsConfig);
        }

        //Dependencies loading
        if (instanceOfFieldType.executeElements.dependencies) {
            if (instanceOfFieldType.executeElements.dependencies.onPreLoad) {
                instanceOfFieldType.executeElements.dependencies.onPreLoad(fei, instanceOfFieldType, resultantFieldElement);
            }

            var dependencyScripts = instanceOfFieldType.executeElements.dependencies.scripts.map(function(script) {
                return fei.instanceOfFormInteraction.fetchFile(fei.formsLibBasePath + script);
            });

            $.each(instanceOfFieldType.executeElements.dependencies.stylesheets, function(key, val) {
                $("head").append("<link href='" + fei.formsLibBasePath + val + "' type='text/css' rel='stylesheet' />");
            });
        }

        //Field execution
        fei.executeFieldType(instanceOfFieldType.name, controls, function(html) {
            $(elementIdentifier).html(html);

            if (instanceOfFieldType.executeElements.dependencies) {
                if (instanceOfFieldType.executeElements.dependencies.onPostLoad) {
                    $.when.apply(null, dependencyScripts).done(function() {
                        instanceOfFieldType.executeElements.dependencies.onPostLoad(fei, instanceOfFieldType, resultantFieldElement);
                    });
                }
            }

            if (resultantFieldElement.initialFocus) {
                setTimeout(function() {
                    $("#" + resultantFieldElement.browserIdentifier + ",[name='" + resultantFieldElement.name + "']").focus();
                }, 10);
            }

            if (instanceOfFieldType.populateData && instanceOfFieldType.populateData.loadFromStruct) {
                var data;
                if (resultantFieldElement.persistInClient && formClientPersistData && formClientPersistData[resultantFieldElement.name]) {
                    data = formClientPersistData;
                } else if (formData && formData[resultantFieldElement.name]) {
                    data = formData;
                }

                if (data) {
                    //validate feild data
                    if (instanceOfFieldType.saveData && instanceOfFieldType.saveData.getFieldJsonSchema) {
                        var fieldData = data[resultantFieldElement.name];
                        var fieldDataSchema = instanceOfFieldType.saveData.getFieldJsonSchema(fei, instanceOfFieldType, resultantFieldElement, {}, resultantFieldElement.name, fieldData);
                        var result = tv4.validateMultiple(fieldData, fieldDataSchema);
                        if (result.valid) {
                            instanceOfFieldType.populateData.loadFromStruct(fei, instanceOfFieldType, resultantFieldElement, data, resultantFieldElement.name);
                        } else {
                            fei.formStructureMessages.addWarnings(fei.createJsonSchemaMessages(result.errors, "WFJFDS", resultantFieldElement.name));
                        }
                    } else {
                        fei.formStructureMessages.addWarning("WMFS902", "The field type '" + instanceOfFieldType.name + "' not contains 'saveData.getFieldJsonSchema' section");
                        instanceOfFieldType.populateData.loadFromStruct(fei, instanceOfFieldType, resultantFieldElement, data, resultantFieldElement.name);
                    }
                }
            } else {
                fei.formStructureMessages.addError("EMCBF809", "The field type '" + instanceOfFieldType.name + "' not contains 'populateData.loadFromStruct' section");
            }

            //BEHAVIORS-START **** LOOKING UP FOR MATCHED TRIGGERS AND ACTIONS WITH THE REGISTERED ONE WHILE ITERATING THE FEI ELEMENTS//
            var behaviors = resultantFieldElement.behaviors;
            if (behaviors) {
                $.each(behaviors, function(index, behavior) {
                    var formTriggers = new FormTriggers(fei, fei.triggerTypes, behavior.triggers, resultantFieldElement, fei.actionTypes, behavior.actions);
                    fei.listOfTriggers.push(formTriggers);
                });
            }

            var initialBehaviors = resultantFieldElement.behaviorsInit;
            if (initialBehaviors && initialBehaviors.actions) {
                var formActions = new FormActions(fei, resultantFieldElement, fei.actionTypes, initialBehaviors.actions);
                fei.listOfInitialActions.push(formActions);
            }
            //BEHAVIORS-END//
        });
    };
    /**
     * Executes the form field.
     *
     * @this {FormExecutorInteraction}
     * @method executeFieldType
     * @param {string} fieldTypeName Name of field type
     * @param {object} controls Field JSON
     * @param {function} callback Callback function.
     */
    this.executeFieldType = function(fieldTypeName, controls, callback) {
        //console.log(controls);
        if (controls.ee.readOnly) {
            callback(controls.ee.defaultValue);
        } else {
            var executeTemplateUrl = this.getFieldTypeBasePath(fieldTypeName) + fieldTypeName + ".execute.js.html";
            this.instanceOfFormInteraction.fetchData(executeTemplateUrl, false, function(template) {
                var hbsTemplate = Handlebars.compile(template);
                var html = hbsTemplate(controls);
                var hbsTemplateDerive = Handlebars.compile(html);
                html = hbsTemplateDerive();
                //console.log(html);
                if (fieldTypeName === 'html') {
                    html = $("<div />").html(html).text();
                }

                callback(html);
            });
        }
    };
    /**
     * Generate the Form Field Data(persist and client persist).
     *
     * @this {FormExecutorInteraction}
     * @method generateFormFieldData
     * @param {string} fieldName Name of the field.
     * @param {object} formJsonDataStruct Data struct of fields.
     * @param {object} onlyClientPersistDataStruct Data struct of client persisted fields.
     */
    this.generateFormFieldData = function(fieldName, formJsonDataStruct, onlyClientPersistDataStruct) {
        var $field = $("[name='" + fieldName + "']");
        var fieldTypeName = $field.attr('data-fieldtype');
        if (fieldTypeName) {
		if(fieldTypeName === "skip") {
			return;
		}
            if (fei.formFieldTypes[fieldTypeName]) {
                var instanceOfFieldType = fei.formFieldTypes[fieldTypeName];
                var fieldDefinition = instanceOfFieldType.mergedDesignElements[fieldName];
                if (instanceOfFieldType.saveData && instanceOfFieldType.saveData.addToStruct) {
                    instanceOfFieldType.saveData.addToStruct(fei, instanceOfFieldType, fieldDefinition, formJsonDataStruct, fieldName);
                } else {
                    fei.formStructureMessages.addError("EMCBF809", "The field type '" + instanceOfFieldType.name + "' not contains 'saveData.addToStruct' section");
                }

                if (fieldDefinition.persistInClient) {
                    if (instanceOfFieldType.saveData && instanceOfFieldType.saveData.addToClientPersistStruct) {
                        instanceOfFieldType.saveData.addToClientPersistStruct(fei, instanceOfFieldType, fieldDefinition, onlyClientPersistDataStruct, fieldName);
                    } else {
                        fei.formStructureMessages.addError("EMCBF809", "The field type '" + instanceOfFieldType.name + "' not contains 'saveData.addToClientPersistStruct' section");
                    }
                }
            } else {
                fei.formStructureMessages.addError("EMFT802", "Couldn't find a registered Field Type with name " + fieldTypeName);
            }
        } else {
            fei.formStructureMessages.addError("EMFA808", "Couldn't find the 'data-fieldtype' attribute in the field " + fieldName);
        }
    };
    /**
     * Generate the Form Data(persist and client persist).
     *
     * @this {FormExecutorInteraction}
     * @method generateFormData
     * @param {string} fieldName Name of the field(optional).
     * @return {object} JSON object with persistData, clientPersistData
     */
    this.generateFormData = function(fieldName) {
        //Clears all the previous errors and warnings before the form save starts
        this.formStructureMessages.clearAllErrorsAndWarnings();

        var formTemplateJson = {};
        var formJsonDataStruct = {};
        var onlyClientPersistDataStruct = {};
        if (fieldName) {
            this.generateFormFieldData(fieldName, formJsonDataStruct, onlyClientPersistDataStruct);
        } else {
            var formId = this.formId;
            var formJsonData = $(formId).serializeArray();
            //console.log(formJsonData);
            $.each(formJsonData, function(i, field) {
                if (!formJsonDataStruct[field.name]) {
                    fei.generateFormFieldData(field.name, formJsonDataStruct, onlyClientPersistDataStruct);
                }
            });
        }

        formTemplateJson.persistData = formJsonDataStruct;
        formTemplateJson.clientPersistData = onlyClientPersistDataStruct;

        return formTemplateJson;
    };
    /**
     * Retrieves the name of the form.
     *
     * @this {FormExecutorInteraction}
     * @method getFormName
     * @param {string} formId Id of form
     * @return {string}
     */
    this.getFormName = function(formId) {
        var thisFormId = formId ? formId : this.formId;
        return $(thisFormId).attr('name');
    };
    /**
     * Retrieves default data of the field(of form DSL).
     *
     * @this {FormExecutorInteraction}
     * @method getFormStructureFieldDefaultData
     * @param {string} fieldName Name of the field.
     * @return {mixed} Value of the field specified in the parameter. Value can be either integer, string etc.
     */
    this.getFormStructureFieldDefaultData = function(fieldName) {
        var formStructureFieldData = "";
        var formStructureData = this.formJsonDSL.data;
        if (formStructureData) {
            formStructureFieldData = formStructureData[fieldName];
        }

        return formStructureFieldData;
    };
    /**
     * Retrieves default data of the Form DSL.
     *
     * @this {FormExecutorInteraction}
     * @method getFormStructureDefaultData
     * @return {object} Value of the field specified in the parameter.
     */
    this.getFormStructureDefaultData = function() {
        return this.formJsonDSL.data;
    };
    /**
     * Retrieves persistence data of the field.
     *
     * @this {FormExecutorInteraction}
     * @method getFormFieldPersistenceData
     * @param {string} fieldName Name of the field.
     * @return {mixed} Value the field specified in the parameter. Value can be either integer, string etc.
     */
    this.getFormFieldPersistenceData = function(fieldName) {
        var formFieldData = this.generateFormData(fieldName);

        return formFieldData.persistData[fieldName];
    };
    /**
     * Retrieves persistence data of the form.
     *
     * @this {FormExecutorInteraction}
     * @method getFormPersistenceData
     * @return {object} Entire persistence data of the form.
     */
    this.getFormPersistenceData = function() {
        var formData = this.generateFormData();

        return formData.persistData;
    };
    /**
     * Retrieves client persistence data of the field.
     *
     * @this {FormExecutorInteraction}
     * @method getFormFieldClientPersistenceData
     * @param {string} fieldName Name of the field.
     * @return {mixed} Value of the field specified in the parameter. Value can be either integer, string etc.
     */
    this.getFormFieldClientPersistenceData = function(fieldName) {
        var formFieldData = this.generateFormData(fieldName);

        return formFieldData.clientPersistData[fieldName];
    };
    /**
     * Retrieves client persistence data of the form.
     *
     * @this {FormExecutorInteraction}
     * @method getFormClientPersistenceData
     * @return {object} Entire client persistence data of the form.
     */
    this.getFormClientPersistenceData = function() {
        var formData = this.generateFormData();

        return formData.clientPersistData;
    };
    /**
     * Retrieves the already persisted data of the field in client(cookie).
     *
     * @this {FormExecutorInteraction}
     * @method getFormFieldClientPersistedData
     * @param {string} fieldName Name of the field.
     * @return {mixed} Value of the field specified in the parameter. Value can be either integer, string etc.
     */
    this.getFormFieldClientPersistedData = function(fieldName) {
        if ($.cookie(this.getFormName())) {
            formFieldClientPersitedData = jQuery.parseJSON($.cookie(fei.getFormName()));
            return formFieldClientPersitedData[fieldName];
        }

        return false;
    };
    /**
     * Retrieves the already persisted data of the form in client(cookie).
     *
     * @this {FormExecutorInteraction}
     * @method getFormClientPersistedData
     * @param {string} formName Name of the form(optional).
     * @return {object} Entire client persisted data of the form.
     */
    this.getFormClientPersistedData = function(formName) {
        var formClientPersitedData = {};
        if ($.cookie(formName ? formName : this.getFormName())) {
            formClientPersitedData = jQuery.parseJSON($.cookie(formName ? formName : this.getFormName()));
        }

        return formClientPersitedData;
    };
    /**
     * Validates the form.
     *
     * @this {FormExecutorInteraction}
     * @method validateForm
     * @param {string} formId Id of the form.
     * @return {boolean} validation result of the form.
     */
    this.validateForm = function(formId) {
        var thisFormId = formId ? formId : this.formId;
        var valid = jQuery(thisFormId).maxForms('validate');
        if (!valid) {
            //alert("MAXForms Validation Failure!");
        }

        return valid;
    };
    /**
     * Submits the form.
     *
     * @this {FormExecutorInteraction}
     * @method submitForm
     * @param {string} formId Id of the form.
     * @param {function} optionalCallback Optional Callback function
     */
    this.submitForm = function(formId, optionalCallback) {
        var thisFormId = formId ? formId : this.formId;
        var valid = this.validateForm(thisFormId);
        if (valid) {
            if (!fei.formStructureMessages.hasErrors()) {
                //Save the client persist data to client persist service.
                var dataSavedToClientPersistence = this.saveFormDataToClientPersistence(thisFormId);

                var formJsonDataStruct = this.getFormPersistenceData();
                if (optionalCallback) {
                    optionalCallback(formJsonDataStruct, dataSavedToClientPersistence);
                } else if (this.onSubmitSuccess) {
                    this.onSubmitSuccess(this, formJsonDataStruct, thisFormId, dataSavedToClientPersistence);
                } else {
                    if (dataSavedToClientPersistence) {
                        alert("Form Submitted Successfully and data saved to client persistence!");
                    } else {
                        alert("Form Submitted Successfully!");
                    }
                }
            } else {
                if (this.onSubmitFailure) {
                    this.onSubmitFailure(this, fei.formStructureMessages);
                } else {
                    fei.formStructureMessages.alertErrors();
                }
            }
        }
    };
    /**
     * Saves form data to persistence service.
     *
     * @this {FormExecutorInteraction}
     * @method saveFormData
     * @param {string} formId Form id
     * @param {function} optionalCallback Optional callback function
     */
    this.saveFormDataToPersistenceService = function(formId, optionalCallback) {
        var thisFormId = formId ? formId : this.formId;
        var formData = this.getFormPersistenceData();
        var formName = this.getFormName(thisFormId);
        var key = uuid.v4();
        var dataName = formName + "-" + key;
        fei.refFormDataPersistence.saveFormData({
            library: "max-forms-harness-es",
            scope: "forms.data",
            formName: formName,
            data: dataName,
            datavalues: formData
        }, function(data) {
            if (optionalCallback) {
                optionalCallback(data);
            } else {
                alert("Form Data Saved Successfully to Persistence Service!");
            }
        });
        //optionalCallback(dataName);
    };
    /**
     * Saves form data to client persistence service.
     *
     * @this {FormExecutorInteraction}
     * @method saveFormDataToClientPersistence
     * @param {string} formId Form id
     * @param {function} optionalCallback Optional callback function
     */
    this.saveFormDataToClientPersistence = function(formId) {
        //Clears all the previous errors and warnings before the form save starts
        //this.formStructureMessages.clearAllErrorsAndWarnings();

        var thisFormId = formId ? formId : this.formId;
        var $form = $(thisFormId);
        var formTypeName = $form.attr('data-formtype');
        var dataSaved = false;
        if ($form.attr('data-persist')) {
            var onlyClientPersistDataString = JSON.stringify(this.getFormClientPersistenceData(thisFormId));
            if (formTypeName) {
                if (fei.formTypes[formTypeName]) {
                    var instanceOfFormType = fei.formTypes[formTypeName];
                    var formDefinition = instanceOfFormType.mergedDesignElements[$form.attr('name')];
                    if (instanceOfFormType.saveData && instanceOfFormType.saveData.persistInClient) {
                        instanceOfFormType.saveData.persistInClient(fei, instanceOfFormType, formDefinition, formDefinition.name, onlyClientPersistDataString);
                        dataSaved = true;
                    } else {
                        fei.formStructureMessages.addError("EMCBF809", "The field type '" + instanceOfFormType.name + "' not contains 'saveData.persistInClient' section");
                    }
                } else {
                    fei.formStructureMessages.addError("EMFT801", "Couldn't find a registered Form Type with name " + formTypeName);
                }
            } else {
                fei.formStructureMessages.addError("EMFA808", "Couldn't find the 'data-formtype' attribute in the form " + $form.attr('name'));
                $.cookie(this.getFormName(thisFormId), onlyClientPersistDataString, {});
                //{ expires: 7, path: '/', domain: 'jquery.com', secure: true }
                dataSaved = true;
            }
        }

        if (dataSaved) {
            return true;
        }
    };

    (function($) {
        $.fn.loadForm = function(formType) {
            //console.log(formType);
            fei.addFormType(formType);
        };

        // jQuery plugin definition
        $.fn.loadField = function(fieldType) {
            //console.log(fieldType);
            fei.addFieldType(fieldType);
        };

        //BEHAVIORS-START **** Registering triggers and actions
        $.fn.loadTriggerType = function(triggerType) {
            //console.log(triggerType);
            fei.addTriggerType(triggerType);
        };

        $.fn.loadActionType = function(actionType) {
            //console.log(actionType);
            fei.addActionType(actionType);
        };
        //BEHAVIORS-END 

        //DYNAMIC VALUES-START **** Registering dynamic value types
        $.fn.loadDynamicValueType = function(dynamicValueType) {
            //console.log(dynamicValueType);
            fei.addDynamicValueType(dynamicValueType);
        };
        //DYNAMIC VALUES-END 
    })(jQuery);

    (function($) {
        $.fn.loadExecutionForm = function(customFieldOptions) {
            //Set the form Id from the Form JSON
            fei.formId = "#" + customFieldOptions.designElements.formID.replace(/\./g, '\\.');

            var defaults = {
                designElements: {},
                validateElements: {
                    preSaveData: "preSaveData",
                    datatype: "data-type",
                    required: "data-required"
                },
                execution: {
                    type: "type",
                    caption: "caption",
                    required: "data-required",
                    placeholder: "placeholderExecution"
                }
            };
            $.each(customFieldOptions.designElements, function(key, val) {
                defaults.designElements[key] = key;
            });
            if (customFieldOptions.designer.fieldElementTemplate) {
                customFieldOptions.designer.fieldElementTemplate(fei);
            }
            if (customFieldOptions.designer.createHtmlMarkupExecution) {
                //alert(fei.formTemplateJson);
                customFieldOptions.designer.createHtmlMarkupExecution(customFieldOptions, defaults, fei, fei.formJsonDSL);
            }
        };
    })(jQuery);
}
