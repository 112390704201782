window.maxFormsConfig = window.maxFormsConfig || {};

(function ($) {
  window.maxFormsConfig = $.extend( true, {}, window.maxFormsConfig, {
    validators: {
      minwords: function ( val, nbWords ) {
        val = val.replace( /(^\s*)|(\s*$)/gi, "" );
        val = val.replace( /[ ]{2,}/gi, " " );
        val = val.replace( /\n /, "\n" );
        val = val.split(' ').length; 
        return val >= nbWords;
      }
      /*, maxFormsminlength: function ( val, min ) {
        console.info("test");
        return val.length >= min;
      }*/
      , maxwords : function ( val, nbWords ) {
        val = val.replace( /(^\s*)|(\s*$)/gi, "" );
        val = val.replace( /[ ]{2,}/gi, " " );
        val = val.replace( /\n /, "\n" );
        val = val.split(' ').length;

        return val <= nbWords;
      }

      , rangewords: function ( val, obj ) {
        val = val.replace( /(^\s*)|(\s*$)/gi, "" );
        val = val.replace( /[ ]{2,}/gi, " " );
        val = val.replace( /\n /, "\n" );
        val = val.split(' ').length;

        return val >= obj[0] && val <= obj[1];
      }

      , greaterthan: function ( val, elem, self ) {
        self.options.validateIfUnchanged = true;

        return new Number(val) > new Number($( elem ).val());
      }

      , lessthan: function ( val, elem, self ) {
        self.options.validateIfUnchanged = true;

        return new Number(val) < new Number($( elem ).val());
      }

      , beforedate: function ( val, elem, self) {
        return Date.parse(val) < Date.parse($(elem).val());
      }

      , afterdate: function ( val, elem, self) {
        return Date.parse($(elem).val()) < Date.parse(val);
      }

      , inlist: function ( val, list, self ) {
        var delimiter = self.options.inlistDelimiter || ',';
        var listItems = (list + "").split(new RegExp("\\s*\\" + delimiter + "\\s*"));

        return (listItems.indexOf(val.trim()) !== -1);
      }

      , luhn: function ( val, elem, self) {
        val = val.replace(/[ -]/g, '');
        var digit, n, sum, _j, _len1, _ref2;
        sum = 0;
        _ref2 = val.split('').reverse();
        for (n = _j = 0, _len1 = _ref2.length; _j < _len1; n = ++_j) {
          digit = _ref2[n];
          digit = +digit;
          if (n % 2) {
            digit *= 2;
            if (digit < 10) {
              sum += digit;
            } else {
              sum += digit - 9;
            }
          } else {
            sum += digit;
          }
        }
        return sum % 10 === 0;
      }

      , americandate: function ( val, elem, self) {
        var regExprn= (/^{{([^}]*)}}$/).test(val); 
        if(regExprn){ 
           return true;   
        }
        if(!/^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/((19|20)[0-9]{2})$/.test(val)) {
          return false;
        }

        var parts = val.split(/[.\/-]+/);
        var day = parseInt(parts[1], 10);
        var month = parseInt(parts[0], 10);
        var year = parseInt(parts[2], 10);
        if(year == 0 || month == 0 || month > 12) {
          return false;
        }
        var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
        if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
          monthLength[1] = 29;
        }

        return day > 0 && day <= monthLength[month - 1];
      }

      , dateval: function ( val, elem, self) {
        var regExprn= (/^{{([^}]*)}}$/).test(val); 
        if(regExprn){ 
           return true;   
        }
        if(!/^([01]?[1-9])[\.\/-]([0-3]?[0-9])[\.\/-]([0-9]{4}|[0-9]{2})$/.test(val)) {
          return false;
        }

        var parts = val.split(/[.\/-]+/);
        var day = parseInt(parts[1], 10);
        var month = parseInt(parts[0], 10);
        var year = parseInt(parts[2], 10);
        if(year == 0 || month == 0 || month > 12) {
          return false;
        }
        var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
        if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
          monthLength[1] = 29;
        }

        return day > 0 && day <= monthLength[month - 1];
      }

      , digitval: function ( val, elem, self) {
        var regExprn= (/^{{([^}]*)}}$/).test(val); 
        var digit= (/^\d+$/).test(val); 
        if(regExprn){ 
           return true;
          
        }else{    
          if(digit){  return true;
          }else{ return false;
          }
        }
      }

      , emailval: function ( val, elem, self) {
        var regExprn= (/^{{([^}]*)}}$/).test(val); 
        var email= (/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i).test(val); 

        if(regExprn){ 
           return true;
          
        }else{    
          if(email){  return true;
          }else{ return false;
          }
        }
      
      }

      , trim: function ( val, elem, self) {
        //$(self.element).val(val.replace(/^\s+/,"").replace(/\s+$/,"").replace(/\s+/g," "));
        $(self.element).val($(self.element).val().replace(/^\s+/,"").replace(/\s+$/,"").replace(/\s+/g," "));

        return true;
      }

      , lowercase: function ( val, elem, self) {   
        //$(self.element).val(val.toLowerCase());
        $(self.element).val($(self.element).val().toLowerCase());

        return true;
      }

      , uppercase: function ( val, elem, self) {    
        //$(self.element).val(val.toUpperCase()); 
        $(self.element).val($(self.element).val().toUpperCase()); 

        return true;
      }

      , phoneval: function ( val, elem, self) {
        var regExprn= (/^{{([^}]*)}}$/).test(val); 
        var phone= (/^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/).test(val); 
        if(regExprn){ 
           return true;         
        }else{    
          if(phone){
            return true;
          }else{
            return false;
          }
        }
      }

      , urlval: function ( val, elem, self) {
        var regExprn= (/^{{([^}]*)}}$/).test(val); 
        var url=(/^((http|https):\/\/)??(www[.])??([a-zA-Z0-9]|-)+?([.][a-zA-Z0-9(-|/|=|?)??]+?)+?$/).test(val);

        if(regExprn){ 
           return true;         
        }else{    
          if(url){  return true;
          }else{ return false;
          }
        }       
      }
    }
    , messages: {
        minwords:       "This value should have %s words at least."
      , maxwords:       "This value should have %s words maximum."
      , rangewords:     "This value should have between %s and %s words."
      , greaterthan:    "This value should be greater than %s."
      , lessthan:       "This value should be less than %s."
      , beforedate:     "This date should be before %s."
      , afterdate:      "This date should be after %s."
      , luhn:           "This value should pass the luhn test."
      , americandate: "This value should be a valid date (MM/DD/YYYY)."
      , digitval:     "Invalid variable or digits - [variable in the format {{variable}} or digits from 0 to 9]"
      , emailval:     "Invalid variable or digits - [variable in the format {{variable}} or an email address"
      , urlval:     "Invalid variable OR URL."
      , dateval:    "Invalid variable or a date format - [variable in the format {{variable}} or date format (MM/DD/YYYY)."
      , phoneval:   "Invalid variable or a Phone Number - [variable in the format {{variable}} or Phone Number format (xxx) xxxx xxxx."
      , maxFormsminlength: "error"
    }
  });
}(window.jQuery || window.Zepto));
