function FormMessage(code, message, location, sourceFile) {
    this.code = code;
    this.message = message;
    this.location = location;
    this.sourceFile = sourceFile;

    this.asText = function() {
        var errorMessage = "";
        if (this.code !== undefined) {
            errorMessage += this.code + ": ";
        }
        if (this.message !== undefined) {
            errorMessage += this.message;
        }
        if (this.location !== undefined) {
            errorMessage += " in " + this.location;
        }
        errorMessage += ".\n";
        return errorMessage;
    };

    this.asHtml = function() {
        var errorMessage = "";
        if (this.code !== undefined) {
            errorMessage += "<b>" + this.code + ":</b> ";
        }
        if (this.message !== undefined) {
            errorMessage += this.message;
        }
        if (this.location !== undefined) {
            errorMessage += " in <b>" + this.location + "</b>";
        }
        errorMessage += ".</br>";
        return errorMessage;
    };
}

function FormMessages() {
    this.informationals = [];
    this.warnings = [];
    this.errors = [];
    this.exceptions = [];
    var fsm = this;

    this.addInfo = function(code, message, location, sourceFile) {};
    this.addWarning = function(code, message, location, sourceFile) {
        this.warnings.push(new FormMessage(code, message, location, sourceFile));
    };
    this.addError = function(code, message, location, sourceFile) {
        this.errors.push(new FormMessage(code, message, location, sourceFile));
    };
    this.addWarnings = function(messageObjectArray) {
        $.each(messageObjectArray, function(index, messageObject) {
            fsm.warnings.push(messageObject);
        });
    };
    this.addErrors = function(messageObjectArray) {
        $.each(messageObjectArray, function(index, messageObject) {
            fsm.errors.push(messageObject);
        });
    };
    this.addException = function(code, message, exception) {};

    this.hasInformationals = function() {
        return this.informationals.length > 0;
    };
    this.hasWarnings = function() {
        return this.warnings.length > 0;
    };
    this.hasErrors = function() {
        return this.errors.length > 0;
    };
    this.hasExceptions = function() {
        return this.exceptions.length > 0;
    };

    this.populateErrors = function(divId) {
        if (divId && this.errors.length) {
            var errorMessage = "";
            $.each(this.errors, function(index, error) {
                errorMessage += error.asHtml();
            });
            //Show the errors in the given div
            $("#" + divId).html(errorMessage);
        }
    };
    this.alertErrors = function() {
        if (this.errors.length) {
            var errorMessage = "";
            $.each(this.errors, function(index, error) {
                errorMessage += error.asText();
            });
            //Show the errors
            alert(errorMessage);
        }
    };

    this.populateWarnings = function(divId) {
        if (divId && this.warnings.length) {
            var warningMessage = "";
            $.each(this.warnings, function(index, warning) {
                warningMessage += warning.asHtml();
            });
            //Show the warnings in the given div
            $("#" + divId).html(warningMessage);
        }
    };
    this.alertWarnings = function() {
        if (this.warnings.length) {
            var warningMessage = "";
            $.each(this.warnings, function(index, warning) {
                warningMessage += warning.asText();
            });
            //Show the warnings
            alert(warningMessage);
        }
    };
    this.clearAllErrorsAndWarnings = function() {
        this.errors.length = 0;
        this.warnings.length = 0;
    };
}

function FormInteraction() {
    var instanceOfFormInteraction = this;

    this.setAsyncProgressNotifier = false;

    this.asyncStartProgress = function() {
        if (this.setAsyncProgressNotifier) {
            $(".ajax-loader").show();
            //$('.fade').addClass('out');
            NProgress.start();
        }
    };

    this.asyncEndProgress = function() {
        if (this.setAsyncProgressNotifier) {
            $(".ajax-loader").hide();
            //NProgress.set(0.4);
            //NProgress.inc();
            NProgress.done();
            $('.fade').removeClass('out');
        }
    };

    this.fetchData = function(path, async, callback) {
        this.asyncStartProgress();
        return $.ajax({
            url: path,
            async: async,
            success: function(data) {
                instanceOfFormInteraction.asyncEndProgress();
                callback(data);
            },
            error: function(e) { /*alert("An error has occured while fetching the data!");*/
                instanceOfFormInteraction.asyncEndProgress();
                console.log(e);
            }
        });
    };

    this.fetchDataJson = function(path, async, callback) {
        this.asyncStartProgress();
        return $.ajax({
            url: path,
            async: async,
            dataType: "json",
            success: function(data) {
                instanceOfFormInteraction.asyncEndProgress();
                callback(data);
            },
            error: function(e) { /*alert("An error has occured while fetching the data!");*/
                instanceOfFormInteraction.asyncEndProgress();
                console.log(e);
            }
        });
    };

    this.fetchDataText = function(path, async, callback) {
        this.asyncStartProgress();
        return $.ajax({
            url: path,
            async: async,
            dataType: "text",
            success: function(data) {
                instanceOfFormInteraction.asyncEndProgress();
                callback(data);
            },
            error: function(e) { /*alert("An error has occured while fetching the data!");*/
                instanceOfFormInteraction.asyncEndProgress();
                console.log(e);
            }
        });
    };

    this.fetchDataXls = function(path, async, callback) {
        this.asyncStartProgress();
        return $.ajax({
            url: path,
            async: async,
            contentType: "application/xls",
            //dataType: "text",
            success: function(data) {
                instanceOfFormInteraction.asyncEndProgress();
                console.log(data);
                callback(data);
            },
            error: function(e) {
                // TODO: get rid of these alerts and use a modern JS logger
                alert("An error has occured while fetching the data: " + e);
                instanceOfFormInteraction.asyncEndProgress();
                console.log(e);
            }
        });
    };

    this.submitData = function(path, data, callback) {
        this.asyncStartProgress();
        return $.ajax({
            url: path,
            type: 'POST',
            contentType: 'application/json',
            data: data,
            dataType: 'json',
            success: function(data) {
                instanceOfFormInteraction.asyncEndProgress();
                callback(data);
            },
            error: function(e) {
                // TODO: get rid of these alerts and use a modern JS logger
                alert("An error has occured while posting the data: " + e);
                instanceOfFormInteraction.asyncEndProgress();
                console.log(e);
            }
        });
    };

    var cache = {};
    this.fetchOrGetCached = function(path, async, callback) {
        if (cache[path]) {
            callback(cache[path]);
        } else {
            this.fetchData(path, async, function(data) {
                cache[path] = data;
                callback(data);
            });
        }
    };

    this.fetchFile = function(path) {
        this.asyncStartProgress();
        return $.getScript(path).always(function() {
            instanceOfFormInteraction.asyncEndProgress();
        });
    };

    this.appendFileToHeadTag = function(src) {
        var script = document.createElement('script');
        script.setAttribute("src", src);
        script.setAttribute("type", "text/javascript");
        document.getElementsByTagName('head')[0].appendChild(script);
    };

    this.registerTypes = function(typePaths, typeContainer, helperParams) {
        var found;
        var typeName;
        var typeFolder;
        var typeFullPath;
        var filter = helperParams.filter ? helperParams.filter : "a";
        var itemType = helperParams.itemType ? helperParams.itemType : "core";
        var folderPattern = helperParams.folderPattern ? helperParams.folderPattern : "../";
        var typePattern = helperParams.typePattern ? helperParams.typePattern : /^[^\?\/].*\/$/;
        var promises = [];
        $.each(typePaths, function(key, typePath) {
            var promise = instanceOfFormInteraction.fetchData(typePath, false, function(data) {
                $(data).find(filter).each(function() {
                    typeFolder = $(this).attr('href');
                    found = typePattern.test(typeFolder);
                    if (found && typeFolder != folderPattern) {
                        if (helperParams.subTypeFolderReplace) {
                            var subTypeFolder = typeFolder.replace(helperParams.subTypeFolderReplace, "");
                            typeName = subTypeFolder.replace("/", "");
                            typeFullPath = typePath + typeFolder + "src/" + subTypeFolder;
                        } else {
                            typeName = typeFolder.replace("/", "");
                            typeFullPath = typePath + typeFolder;
                        }
                        typeContainer[typeName] = {
                            path: typeFullPath,
                            type: itemType
                        };
                        //Load the type plugin
                        if (helperParams.loadPlugin) {
                            instanceOfFormInteraction.fetchFile(typeFullPath + typeName + ".js");
                        }
                    }
                });
            });
            promises.push(promise);
        });
        return promises;
    };

    this.appendHtmlOfFetchedMarkdownToDom = function(path, domId) {
        if (cache[path]) {
            $("#" + domId).html(markdown.toHTML(cache[path]));
        } else {
            this.fetchDataText(path, true, function(md) {
                cache[path] = md;
                $("#" + domId).html(markdown.toHTML(md));
            });
        }
    };

    this.getQueryString = function(url) {
        if (url) {
            var arr = url.split('&');
            if (arr === "") return {};
            var obj = {};
            for (var i = 0; i < arr.length; ++i) {
                var items = arr[i].split('=');
                if (items.length != 2) continue;
                obj[items[0]] = decodeURIComponent(items[1].replace(/\+/g, " "));
            }
            return obj;
        } else {
            return {};
        }
    };

    this.differenceOfObjects = function(original, changed) {

        var temp = angular.toJson(changed); // to remove the hashKey
        changed = angular.fromJson(temp); // convert back the json string (in which hashKey is removed)to json object

        var difference = {};
        for (var name in original) {
            if (name in changed) {
                if ((typeof changed[name] == "object") && !$.isArray(changed[name])) {
                    var diff = this.differenceOfObjects(original[name], changed[name]);
                    if (!jQuery.isEmptyObject(diff)) {
                        difference[name] = diff;
                    }
                } else if ($.isArray(changed[name])) {
                    if (changed[name].length != original[name].length) {
                        difference[name] = changed[name];
                    } else {
                        temp = [];
                        $.each(changed[name], function(k, v) {
                            if (jQuery.isPlainObject(v) && jQuery.isPlainObject(original[name][k])) {
                                var diff = instanceOfFormInteraction.differenceOfObjects(original[name][k], v);
                                if (!jQuery.isEmptyObject(diff)) {
                                    temp.push(diff);
                                }
                            }
                        });
                        if (temp.length)
                            difference[name] = temp;
                    }
                } else if (original[name] != changed[name]) {
                    difference[name] = changed[name];
                }
            }
        }

        for (var k in changed) {
            if (!(k in original))
                difference[k] = changed[k];
        }

        return difference;
    }
}

(function($) {
    $.ajaxSetup({
        //cache: false
    });
})(jQuery);